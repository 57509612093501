import React from 'react';
import '../../css/FoodMenu.css';
import { Link } from 'react-router-dom';

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <h1><u><Link to="/lunch/ostersund/sir_winston">Sir Winston (Fredagar)</Link></u></h1>
      <div className="day">
        <p className="menu-item">Fredagsmeny</p>
      </div>
    </div>
  );
};

export default Menu;
