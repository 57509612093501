import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import ArtutMenu from './artut_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Restaurang Artut | Dagens Lunch i Östersund</title>
        <meta name="description" content="God mat gör människan lycklig. Varje dag går vi till jobbet med ambitionen att göra det bästa vi kan med dom bästa råvarorna vi kan få tag på. Vi värnar starkt om de lokala mathantverkarna och är oerhört stolta över att få jobba med så skickliga yrkeskvinnor & män. Luta er tillbaka, och ha en fin kväll." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/artut" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/artut/artut_banner.jpg" alt="Artut" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/lunch/ostersund', active: false },
        { name: 'Artut', url: '', active: true },
      ]} />

      <div className="columns">
        <div className="column column-1">
          <h2>Välkomna till bords</h2>
          <p>God mat gör människan lycklig. Varje dag går vi till jobbet med ambitionen att göra det bästa vi kan med dom bästa råvarorna vi kan få tag på. Vi värnar starkt om de lokala mathantverkarna och är oerhört stolta över att få jobba med så skickliga yrkeskvinnor & män. Luta er tillbaka, och ha en fin kväll.<br></br>/ Artut
          </p>
          <p><a href='https://www.artutbar.se/' target='blank'>https://www.artutbar.se/</a></p>
        </div>
        <div className="column column-2">
          <ArtutMenu />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
