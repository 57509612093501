import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import HotellOstersundMeny from './hotellostersund_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Hotell Östersund | Dagens Lunch i Östersund</title>
        <meta name="description" content="Njut av en välsmakande lunch hos oss på Hotell Östersund & O'Learys. Maten är lagad från grunden och med stor omsorg. Lunchen vill vi att ni skall uppleva som smakfullt komponerade anrättningar. Husmanskost med inspiration från alla världens hörn." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/hotell_ostersund" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/hotellostersund/hotellostersund_banner.jpg" alt="Hotell Östersund" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/lunch/ostersund', active: false },
        { name: 'Hotell Östersund', url: '', active: true },
      ]} />

      <div className="columns">
        <div className="column column-1">
          <h2>Hotell Östersund</h2>
          <p>Njut av en välsmakande lunch hos oss på Hotell Östersund & O'Learys. Maten är lagad från grunden och med stor omsorg. Lunchen vill vi att ni skall uppleva som smakfullt komponerade anrättningar. Husmanskost med inspiration från alla världens hörn.</p>
          <p><a href='https://www.hotellostersund.se/lunch' target='blank'>https://www.hotellostersund.se/lunch</a></p>
        </div>
        <div className="column column-2">
          <HotellOstersundMeny initialShowWholeWeek={true} />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
