import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getCurrentWeekNumber from '../../week';
import '../../css/FoodMenu.css';
import { Link } from 'react-router-dom';

const ArtutMenu = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const url = '/data/artut/artut_week_'+getCurrentWeekNumber()+'.json';
        const result = await axios(url);
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setData('No data found');
      }
      
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Laddar veckans meny...</div>;
  } else {
    if (data === 'No data found') {
      return (
        <div className="menu-wrapper">
          <h1><u><Link to="/lunch/ostersund/artut">Artut</Link></u> (Fredagar)</h1>
          <div>Det finns ingen meny för denna vecka än</div>
        </div>
      );
    } else {
      return (
        <div className="menu-wrapper">
          <h1><u><Link to="/lunch/ostersund/artut">Artut</Link></u> (Fredagar)</h1>
          {
            data.day && Object.keys(data.day).map(
              (day, i) => {
                if (i === new Date().getDay() -1 ) {
                  return (
                    <div className="day" key={i}>
                      <h2 className="day-name">{data.day[i].title}</h2>
                      <p className="menu-item">{data.day[i].text}</p>
                    </div>
                  );
                }
                return null;
                }
              )
          }
          <hr></hr>
          {data.general && data.general.map((item, i) => <p key={i}>{item}</p>)}
        </div>
      );
    }
  }
};

export default ArtutMenu;
