import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import Oense from './oense_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Oense | Dagens Lunch i Östersund</title>
        <meta name="description" content="Hos oss är alla välkomna, från morgon till kväll, oavsett om du söker en lugn kaffe med egna tankar, en förtrolig fika med vän, en skrattfylld lunch med kollegor eller en livlig middag med vänner." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/oense" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/oense/oense_banner.jpg" alt="Oense" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/lunch/ostersund', active: false },
        { name: 'Oense', url: '', active: true },
      ]} />

      <div className="columns">
        <div className="column column-1">
          <h2>Oense</h2>
          <p>Hos oss är alla välkomna, från morgon till kväll, oavsett om du söker en lugn kaffe med egna tankar, en förtrolig fika med vän, en skrattfylld lunch med kollegor eller en livlig middag med vänner.</p>
          <p><a href='https://www.oense.nu/' target='blank'>https://www.oense.nu/</a></p>
        </div>
        <div className="column column-2">
          <Oense initialShowWholeWeek={true} />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
