import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import TreRumMeny from './trerum_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Restaurang Tre Rum | Dagens Lunch i Östersund</title>
        <meta name="description" content="God mat och bra service i hemtrevlig miljö. Ni hittar oss i folkvimlet mitt på Östersunds Gågata." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/tre_rum" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/trerum/trerum_banner.jpg" alt="Tre Rum" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/lunch/ostersund', active: false },
        { name: 'Tre Rum', url: '', active: true },
      ]} />

      <div className="columns">
        <div className="column column-1">
          <h2>Restaurang Tre Rum</h2>
          <p>God mat och bra service i hemtrevlig miljö. Ni hittar oss i folkvimlet mitt på Östersunds Gågata.</p>
          <p><a href='https://www.restaurangtrerum.se/' target='blank'>https://www.restaurangtrerum.se/</a></p>
        </div>
        <div className="column column-2">
          <TreRumMeny initialShowWholeWeek={true} />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
