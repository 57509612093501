import React from 'react';
import '../../css/FoodMenu.css';

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <h1>Snabbmatställen</h1>
      <div className="day">
        <h2 className="day-name">Kebab Baren</h2>
        <p className="menu-item">Kebab</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Via Grande</h2>
        <p className="menu-item">Pizzeria</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Sibylla</h2>
        <p className="menu-item">Hamburgare</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Bastard Burgers</h2>
        <p className="menu-item">Hamburgare</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Gusto</h2>
        <p className="menu-item">Pizza, Pasta, Salad, Hamburgare</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Subway</h2>
        <p className="menu-item">Smörgåsar</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Tacobar</h2>
        <p className="menu-item">Tacos, Burritos, Natchos, Burgare, Sallad</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Klang</h2>
        <p className="menu-item">Burgare, Pizza, Sallad, Tacos, Fish & Chips, Schnitzel, Mnazaleh, Högrevschili, Pan Fried Cod</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Basta</h2>
        <p className="menu-item">
          Pizza, Pasta
          <br></br><br></br>
          ALLTID PÅ DAGENS<br></br>
          Krämig parmesansås, stekt pancetta, svartpeppar.<br></br>
          <br></br>
          CEASAR SALLAD<br></br>
          Romansallad, caesardressing, rödlök, grönkål,<br></br>
          krispig pancetta, hyvlad parmesan, krutonger & kyckling.<br></br>
          <br></br>
          PIZZA DELIZIA VEGANA<br></br>
          Tomatsås, rostade grönsaker, rödlök, ruccola, olivolja.<br></br>
          <br></br>
          135:-<br></br>
          Inklusive kaffe, focaccia, sallad<br></br>
          LÄSK 30:-, VIN, ÖL, PROSECCO 79:-
        </p>
      </div>
      <hr></hr>
    </div>
  );
};

export default Menu;
