import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import HosAndreasCityMeny from './hosandreascity_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Restaurang Hos Andreas | Dagens Lunch i Östersund</title>
        <meta name="description" content="Hos oss kan upptäcka underbar mat! Ät en god lunch med allt från husmanskost till krispiga sallader. Om du vill göra det extra lyxigt så har vi även catering för bröllop, invigningar och kick off." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/hos_andreas" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/hosandreas/hosandreas_banner.jpg" alt="Hos Andreas" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/lunch/ostersund', active: false },
        { name: 'Hos Andreas', url: '', active: true },
      ]} />
      <div className="columns">
        <div className="column column-1">
          <h2>Lunchrestaurangen i hjärtat av Östersund</h2>
          <p>Hos oss kan upptäcka underbar mat! Ät en god lunch med allt från husmanskost till krispiga sallader. Om du vill göra det extra lyxigt så har vi även catering för bröllop, invigningar och kick off.
          </p>
          <p><a href='https://www.hosandreas.se/' target='blank'>https://www.hosandreas.se/</a></p>
        </div>
        <div className="column column-2">
          <HosAndreasCityMeny initialShowWholeWeek={true} />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
