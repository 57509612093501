import React from 'react';
import '../../css/FoodMenu.css';

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <h1>Asiatisk buffé</h1>
      <div className="day">
        <h2 className="day-name">Sammurai</h2>
        <p className="menu-item">Asiatisk buffé</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">Shanghai Wok</h2>
        <p className="menu-item">Asiatiskt</p>
      </div>
      <hr></hr>

      <div className="day">
        <h2 className="day-name">BBQ Garden</h2>
        <p className="menu-item">Asiatisk buffé och en daglig husman</p>
      </div>
      <hr></hr>
    </div>
  );
};

export default Menu;
