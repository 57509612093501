import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar">
      {!isOpen ? (
        <button onClick={() => setIsOpen(true)} className="menu-icon">
          ☰ Lunch {/* This is a unicode character for a "hamburger" menu icon */}
        </button>
      ) : (
        <div className="menu">
          <button onClick={() => setIsOpen(false)} className="close-menu-button">
            × {/* This is a unicode character for a "close" icon */}
          </button>
          <ul className="menu-list">
            <li><h1><Link to="/" onClick={() => setIsOpen(false)}>Dagens lunch</Link></h1></li>

            <li className='menu-city'><Link to="/lunch/ostersund" onClick={() => setIsOpen(false)}>Östersund</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/artut" onClick={() => setIsOpen(false)}>Artut</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/hos_andreas" onClick={() => setIsOpen(false)}>Hos Andreas</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/hotell_ostersund" onClick={() => setIsOpen(false)}>Hotell Östersund</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/sir_winston" onClick={() => setIsOpen(false)}>Sir Winston</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/tre_rum" onClick={() => setIsOpen(false)}>Tre Rum</Link></li>
            <li className='menu-restaurant'><Link to="/lunch/ostersund/oense" onClick={() => setIsOpen(false)}>Oense</Link></li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
