import React from 'react';
import { Helmet } from 'react-helmet';
import './css/FoodMenu.css';

import TreRum from './cities/ostersund/trerum_meny';
import HotellOstersund from './cities/ostersund/hotellostersund_meny';
import HosAndreas from './cities/ostersund/hosandreascity_meny'
import Snabbmat from './cities/ostersund/snabbmat_meny'
import Buffe from './cities/ostersund/buffe_meny'
import SirWinston from './cities/ostersund/sirwinston_meny'
import Artut from './cities/ostersund/artut_meny'
import Oense from './cities/ostersund/oense_meny';

function App() {
  return (
    <div className="App floating-div-wrap">
      <Helmet>
        <title>Dagens Lunch i Östersund</title>
        <meta name="description" content="Hitta dagens lunch menyer i Östersund på dagenslunch.info. Uppdateras dagligen med de senaste menyerna från lokala restauranger." />
        {/* Other meta tags */}
      </Helmet>
      <h1 style={{ 
                  backgroundColor: '#2b4546', 
                  color: 'white', 
                  padding: '20px'
                }}>Dagens lunch i Östersunds centrum, smidigt samlat på ett ställe.</h1>
      <div className="floating-container">
          <div className="floating-item">
            <TreRum />
          </div>
          <div className="floating-item">
            <HotellOstersund />
          </div>
          <div className="floating-item">
            <HosAndreas />
          </div>
          <div className="floating-item">
            <Oense />
          </div>
          <div className="floating-item">
            <SirWinston />
          </div>
          <div className="floating-item">
            <Artut />
          </div>
          <div className="floating-item">
            <Buffe />
          </div>
          <div className="floating-item">
            <Snabbmat />
          </div>
      </div>
    </div>
  );
}

export default App;
