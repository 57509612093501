function Breadcrumb({ items }) {
  return (
    <div className="breadcrumb-outer-container">
      <div className="breadcrumb-container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {items.map((item, index) => (
              <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`} aria-current={item.active ? 'page' : undefined}>
                {item.active ? (
                  <span>{item.name}</span>
                ) : (
                  <a href={item.url}>{item.name}</a>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumb;
