
import '../../css/Cities.css';
import Breadcrumb from '../../Breadcrumbs';


function OstersundRestauranger() {
  return (
    <div>
      <div>
      <Breadcrumb items={[
          { name: 'Hem', url: '/', active: false },
          { name: 'Östersund', url: '/ostersund', active: false },
          { name: 'Restauranger', url: '/ostersund/restauranger', active: true },
        ]} />
      </div>
      <div className="city">
      <ul className="city-list">
        <li><h1>Östersund</h1></li>
        <li className='city-restaurant'><a href="/ostersund/restauranger/artut">Artut</a></li>
        <li className='city-restaurant'><a href="/ostersund/restauranger/hos_andreas">Hos Andreas</a></li>
        <li className='city-restaurant'><a href="/ostersund/restauranger/hotell_ostersund">Hotell Östersund</a></li>
        <li className='city-restaurant'><a href="/ostersund/restauranger/sir_winston">Sir Winston</a></li>
        <li className='city-restaurant'><a href="/ostersund/restauranger/tre_rum">Tre Rum</a></li>
      </ul>
    </div>
  </div>
  );
}

export default OstersundRestauranger;
