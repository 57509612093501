import './css/App.css';
import Navbar from './Navbar';

import Start from './Start';

// import Ostersund from './cities/Ostersund';
import OstersundRestauranger from './cities/ostersund/restauranger';

import TreRum from './cities/ostersund/trerum';
import HotellOstersund from './cities/ostersund/hotellostersund';
import HosAndreas from './cities/ostersund/hosandreascity'
import Snabbmat from './cities/ostersund/snabbmat_meny'
import Buffe from './cities/ostersund/buffe_meny'
import SirWinston from './cities/ostersund/sirwinston'
import Artut from './cities/ostersund/artut'
import Oense from './cities/ostersund/oense'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Start />} />
        <Route path="/lunch/ostersund" element={<OstersundRestauranger />} />
        <Route path="/lunch/ostersund/tre_rum" element={<TreRum />} />
        <Route path="/lunch/ostersund/hotell_ostersund" element={<HotellOstersund />} />
        <Route path="/lunch/ostersund/hos_andreas" element={<HosAndreas />} />
        <Route path="/lunch/ostersund/artut" element={<Artut />} />
        <Route path="/lunch/ostersund/sir_winston" element={<SirWinston />} />
        <Route path="/lunch/ostersund/snabbmat" element={<Snabbmat />} />
        <Route path="/lunch/ostersund/buffe" element={<Buffe />} />
        <Route path="/lunch/ostersund/oense" element={<Oense />} />
      </Routes>
    </Router>
  );
}

export default App;

