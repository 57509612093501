import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Restaurant.css';
import SirWinstonMeny from './sirwinston_meny';
import Breadcrumb from '../../Breadcrumbs';

function Restaurant() {
  return (
    <div className="Restaurant">
      <Helmet>
        <title>Sir Winston | Dagens Lunch i Östersund</title>
        <meta name="description" content="Kom in och njut av stämningen i puben eller njut av goda smaker från vår välsmakande meny i matsalen, eller en stunds barhäng. Sir Winston Restaurant & Bar har öppet för Dig alla veckans sju dagar, välj mat ur restaurangens breda och välsmakande meny. Vi prioriterar gärna lokala produkter och producenter i vårt kök." />
        <link rel="canonical" href="https://dagenslunch.info/lunch/ostersund/sir_winston" />
        {/* Other meta tags */}
      </Helmet>
      <div className="image-container">
        <img src="/images/ostersund/sirwinston/sirwinston_banner.jpg" alt="Sir Winston" />
      </div>
      <Breadcrumb items={[
        { name: 'Dagens lunch', url: '/', active: false },
        { name: 'Östersund', url: '/ostersund', active: false },
        { name: 'Sir Winston', url: '', active: true },
      ]} />

      <div className="columns">
        <div className="column column-1">
          <h2>En av Östersunds mest välbesökta och populära restauranger.</h2>
          <p>Kom in och njut av stämningen i puben eller njut av goda smaker från vår välsmakande meny i matsalen, eller en stunds barhäng. Sir Winston Restaurant & Bar har öppet för Dig alla veckans sju dagar, välj mat ur restaurangens breda och välsmakande meny. Vi prioriterar gärna lokala produkter och producenter i vårt kök.</p>
          <p><a href='https://www.sirwinston.se/sir-winston' target='blank'>https://www.sirwinston.se/sir-winston</a></p>
        </div>
        <div className="column column-2">
          <SirWinstonMeny />
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
